"use client";
import Home from "@/components/home/home";

export default function App() {
  return (
    <div>
      <Home />
    </div>
  );
}
